import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { remainingTimeForFreePlay } from 'src/utils/freePlayUtils';
import { GET_USER_STATUS, CHECK_USER_PLAY } from 'src/graphql';
import TagManager from 'react-gtm-module';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Box } from '@mui/material';
import getErrorCode from '../utils/getErrorCode';
import Page from '../components/Page';

const ContainerStyle = styled(Container)(({ theme }) => ({
  marginLeft: '0',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const ProceedPage = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  let userPlayFinishFlag = false;
  let userStatusFinishFlag = false;
  const [checkUserPlay] = useMutation(CHECK_USER_PLAY, {
    onCompleted: (data) => {
      if (data.checkUserPlay.responseCode == 800) {
        navigate('/open/games');
        return;
      }
      if (data.checkUserPlay.responseCode == 700) {
        navigate('/subscribe');
        return;
      }
      if (userStatusFinishFlag) {
        navigate('/auth');
        return;
      }
      userPlayFinishFlag = true;
    }
  });
  useEffect(() => {
    checkUserPlay();
  }, []);
  const { error, data, loading } = useQuery(GET_USER_STATUS, {
    onCompleted: (data) => {
      let msisdn = Cookies.get('msisdn') || '';

      if (!msisdn) {
        setMessage('Please turn off WiFi and use your mobile data.');

      } else {
        if (error && error.graphQLErrors && getErrorCode(error) === 404) {
          localStorage.setItem('subscribed', '');
          console.log(error)
          console.log(error.graphQLErrors)

        }
        if (data && data.getUserStatus && data.getUserStatus.msisdn) {
          if (data.getUserStatus.responseCode === 200) {
            localStorage.setItem('msisdn', data.getUserStatus.msisdn);
            localStorage.setItem('subscribed', true);
            TagManager.dataLayer({
              dataLayer: {
                event: 'userSubscribed',
                userId: data.getUserStatus.msisdn
              }
            });
            navigate('/app/games');
          } else if (data.getUserStatus.responseCode === 300) {
            localStorage.setItem('msisdn', data.getUserStatus.msisdn);
            localStorage.setItem('subscribed', '');
            localStorage.setItem('newUser', 'newUser');
            navigate('/open/games');
          }
        } else if (userPlayFinishFlag) {
          navigate('/auth');
        } else {
          userStatusFinishFlag = true;
        }
      }
    }
  });

  const proceed = () => {
    const remainingTime = remainingTimeForFreePlay();
    if (remainingTime && remainingTime > 0) {
      navigate('/open/games');
    }
    else {
      navigate('/');
    }
  };
  return (
    <Page
      title="Subscribe | Games Poa App"
      sx={{
        backgroundImage: 'url(/static/proceed-bg.png)',
        height: '100%',
        backgroundPosition: 'right top',
        backgroundSize: 'cover'
      }}
    >
      <Box sx={{ background: 'rgba(0,0,0,0.4)', height: '100%' }}>
        <Box
          component="img"
          src="/static/gamespoa-text.svg"
          sx={{ width: 200, margin: '0px auto', padding: '50px 0' }}
        />

        <ContainerStyle>
          <Typography variant="h3" sx={{ color: 'white', fontFamily: 'lemonmilk' }}>
            CONGRATULATIONS
            <br />
            FOR
            <br />
            JOINING THE FUN
          </Typography>
          <Typography
            paragraph="true"
            sx={{
              textAlign: 'left',
              color: 'red',
              marginTop: '20px',
              marginLeft: '40px',
              fontWeight: 'bold'
            }}
          >
            {message}
          </Typography>
          <Button
            size="large"
            onClick={proceed}
            aria-label="close"
            sx={{
              backgroundColor: '#FFFF20',
              color: '#000000',
              fontSize: '38px',
              borderRadius: '15px',
              padding: '16px 25px 10px 25px',
              fontFamily: 'abrade',
              '&:hover': {
                backgroundColor: '#FFFF77',
                color: '#333333'
              }
            }}
          >
            Proceed
          </Button>
          <Typography
            paragraph="true"
            sx={{ textAlign: 'left', color: 'white', marginLeft: '40px', marginTop: '20px', fontWeight: 'bold' }}
          >
            If you face any problems call 0724&nbsp;549&nbsp;695 for assistance.
          </Typography>
        </ContainerStyle>
      </Box>
    </Page>
  );
};

export default ProceedPage;
