import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
import { checkHashHeader } from 'src/utils/checkHashHeader';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

const wifiMessage = (
  <div>
    <motion.div variants={varBounceIn}>
      <Typography variant="h6" paragraph>
        Oops!
      </Typography>
      <Typography variant="h4" paragraph>
        Something Went Wrong.
      </Typography>
    </motion.div>
    <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
      Please switch to mobile data and refresh the page in order to sign in.
    </Typography>
  </div>
);

const HeDisable = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    checkHashHeader().then(
      () => {
        setMessage(wifiMessage);
      },
      () => {
        setMessage(wifiMessage);
      }
    );
  }, []);

  return (
    <RootStyle title="WiFi detected | Games Poa App">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="https://cdn0.iconfinder.com/data/icons/shift-free/32/Error-512.png"
                sx={{ height: 80, mx: 'auto', filter: 'invert(23%) sepia(99%) saturate(2192%) hue-rotate(191deg) brightness(101%) contrast(102%)' }} // Blue color filter
              />
            </motion.div>
            {message}
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default HeDisable;
