import { gql } from '@apollo/client';

const GET_SUMMARY_GAMES = gql`
  query GetSummaryGames {
    getSummaryGames {
      category
      games {
        id
        gametitle
        url
        thumbnailUrl
      }
    }
  }
`;

export default GET_SUMMARY_GAMES;
