import { gql } from '@apollo/client';

const GET_FREE_GAMES = gql`
  query GetFreeGames($category: String!) {
    freeGames(category: $category) {
      id
      gametitle
      url
      thumbnailUrl
    }
  }
`;

export default GET_FREE_GAMES;
