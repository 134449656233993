import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Home from './pages/Home';
import FreeGames from './pages/FreeGames';
import PremiumGames from './pages/PremiumGames';
import PremiumCategoryGames from './pages/PremiumCategoryGames';
import FreeCategorygGames from './pages/FreeCategorygGames';
import NotSubscribed from './pages/NotSubscribed';
import Subscribe from './pages/Subscribe';
import Proceed from './pages/Proceed';
import HeDisable from './pages/HeDisable';
import Play from './pages/Play';
import TermsAndConditions from './pages/TermsAndConditions';
import Processing from './pages/Processing';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: '/', element: <Home /> },
        { path: 'auth', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: 'subscribe', element: <Subscribe /> },
        { path: 'not-subscribe', element: <NotSubscribed /> },
        { path: 'terms-conditions', element: <TermsAndConditions /> },
        { path: 'processing', element: <Processing /> }
      ]
    },
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app/games" replace /> },
        { path: 'games', element: <PremiumGames /> },
        { path: 'games/:gameType', element: <PremiumCategoryGames /> }
      ]
    },
    {
      path: '/open',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/open/games" replace /> },
        { path: 'games', element: <FreeGames /> },
        { path: 'games/:gameType', element: <FreeCategorygGames /> }
      ]
    },
    {
      path: '/proceed',
      element: <Proceed />
    },
    {
      path: '/he-disable',
      element: <HeDisable />
    },
    {
      path: '/play',
      element: <Play />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
