import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import getUrlParamValue from 'src/utils/getUrlParamValue';
// material
import { Box, Alert, Typography, Link } from '@mui/material';
// components
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { MotionContainer, varBounceIn } from './animate';
import Cookies from 'js-cookie';

import { SUBSCRIBE_USER } from '../graphql';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const Subscribe = ({ code }) => {
  const navigate = useNavigate();
  const [subscribeUser, { loading, error }] = useMutation(SUBSCRIBE_USER);

  let param = getUrlParamValue('m');
  if (param) {
    param = 'Please credit account to continue enjoying service or call 0724549695 for assistance.';
  } else {
    param = 'There is always something fun to play.';
  }
  const handleSubscription = async () => {
    await subscribeUser({
      onCompleted: async (data) => {
        console.log(data); // Log the data object

        if (data.subscribeUser.responseCode === 202) {
          localStorage.setItem('msisdn', data.subscribeUser.msisdn);
          Cookies.set('msisdn', data.subscribeUser.msisdn);

          navigate('/processing');
        }
      }
    });
  };

  const disagree = async () => {
    localStorage.setItem('newUser', 'newUser');
    navigate('/open/games');
  };

  return (
    <MotionContainer initial="initial" open>
      <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
        <motion.div variants={varBounceIn}>
          <Box component="img" src="/static/newlogo.png" sx={{ height: 200, mx: 'auto' }} />
        </motion.div>
        <motion.div variants={varBounceIn}>
          <Typography variant="h4" paragraph>
            Hundreds of games to play.
          </Typography>
        </motion.div>
        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>{param}</Typography>

        <Typography sx={{ color: 'text.secondary', marginBottom: '24px' }}>
          {code === 304 && (
            <>You don't have an active subscription. Please subscribe to continue.</>
          )}
          {code === 404 && (
            <>You don't have an active subscription. Please subscribe to continue.</>
          )}
          {code === 504 && (
            <>You don't have an active subscription. Please subscribe to continue.</>
          )}
          {error && <Alert severity="error">{error.message}</Alert>}
        </Typography>

        <LoadingButton
          onClick={handleSubscription}
          sx={{ width: '280px', marginBottom: '24px', display: 'none' }}
          size="large"
          variant="contained"
          loading={loading}
        >
          Subscribe
        </LoadingButton>

        <LoadingButton
          onClick={disagree}
          color="error"
          sx={{ width: '280px', display: 'none' }}
          size="large"
          variant="outlined"
        >
          Cancel
        </LoadingButton>

        <Typography sx={{ color: 'text.secondary', margin: '24px' }}>
          Cost kshs. 15 per day
        </Typography>
        <Typography variant="subtitle2" sx={{ marginTop: '4px', textAlign: 'center' }}>
          <Link to="/terms-conditions" component={RouterLink}>
            T&amp;Cs
          </Link>
        </Typography>
      </Box>
    </MotionContainer>
  );
};

Subscribe.propTypes = {
  code: PropTypes.number
};

export default Subscribe;
