const sidebarConfig = {
  premium: [
    {
      title: 'Home',
      path: '/app/games'
    },
    {
      title: 'Adventure Games',
      path: '/app/games/Adventure'
    },
    {
      title: 'Arcade Games',
      path: '/app/games/Arcade'
    },
    {
      title: 'Board Games',
      path: '/app/games/Board'
    },
    {
      title: 'Classics Games',
      path: '/app/games/Classics'
    },
    {
      title: 'Junior Games',
      path: '/app/games/Junior'
    },
    {
      title: 'Puzzles Games',
      path: '/app/games/Puzzles'
    },
    {
      title: 'Sports Games',
      path: '/app/games/Sports'
    },
    {
      title: 'Strategy Games',
      path: '/app/games/Strategy'
    }
  ],
  free: [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'Adventure Games',
      path: '/open/games/Adventure'
    },
    {
      title: 'Arcade Games',
      path: '/open/games/Arcade'
    },
    {
      title: 'Board Games',
      path: '/open/games/Board'
    },
    {
      title: 'Classics Games',
      path: '/open/games/Classics'
    },
    {
      title: 'Junior Games',
      path: '/open/games/Junior'
    },
    {
      title: 'Puzzles Games',
      path: '/open/games/Puzzles'
    },
    {
      title: 'Sports Games',
      path: '/open/games/Sports'
    },
    {
      title: 'Strategy Games',
      path: '/open/games/Strategy'
    }
  ]
};

export default sidebarConfig;
