import { Container, Alert } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Page from '../components/Page';
import { AppGamesTypeList } from '../components/_dashboard/app';
import { GET_USER_CATEGORY_GAMES } from '../graphql';
import Spinner from '../components/Spinner';

const PremiumCategoryGames = () => {
  const { gameType } = useParams();
  const { loading, error, data } = useQuery(GET_USER_CATEGORY_GAMES, {
    variables: { category: gameType }
  });

  return (
    <Page title="Home | Games Poa App">
      <Container maxWidth="xl">
        {loading && <Spinner />}
        {data && <AppGamesTypeList games={data.getUserCategoryGames} title={`${gameType} Games`} />}
        {error && <Alert severity="error">There was an error. Please try again later.</Alert>}
      </Container>
    </Page>
  );
};

export default PremiumCategoryGames;
