import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Card, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FullScreenDialog from '../../FullScreenDialog';

const GameImgStyle = styled('img')({
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  borderRadius: '5px 5px 0 0'
});

const GameItem = ({ game, handleUserPlay }) => {
  const { thumbnailUrl, gametitle } = game;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (handleUserPlay) {
      handleUserPlay();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <Box style={{ mt: 2, position: 'relative', width: 250, height: 140 }} xs={{ height: 160 }}>
          <GameImgStyle alt={gametitle} src={thumbnailUrl} />
        </Box>
        <Stack
          spacing={2}
          alignItems="stretch"
          justifyContent="space-between"
          py={2}
          pr={2}
          width="100%"
        >
          <Typography variant="body1" textAlign="left">
            {gametitle}
          </Typography>
          <Button
            fullWidth
            size="small"
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
          >
            Play
          </Button>
        </Stack>
      </Stack>
      <FullScreenDialog open={open} handleClose={handleClose} game={game} />
    </Card>
  );
};

GameItem.propTypes = {
  game: PropTypes.object.isRequired,
  handleUserPlay: PropTypes.func
};

export default GameItem;
