import { gql } from '@apollo/client';

const CHECK_REMAINING_TIME = gql`
  mutation CheckRemainingTime {
    checkRemainingTime {
      minutes_remaining
      msisdn
    }
  }
`;

export default CHECK_REMAINING_TIME;
