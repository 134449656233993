import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { remainingTimeForFreePlay } from 'src/utils/freePlayUtils';
import Page from '../components/Page';
import { AppSummaryGames } from '../components/_dashboard/app';
import { CHECK_USER_PLAY, CHECK_REMAINING_TIME } from '../graphql';
import { isFreePlayAvailable } from 'src/utils/freePlayUtils';
import { APP_CONFIG } from 'src/app-config';

const FreeGames = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [minutesRemainingNumber, setMinutesRemainingNumber] = useState(null);

  const [checkUserPlay] = useMutation(CHECK_USER_PLAY, {
    onError: () => {
      if (!isFreePlayAvailable()) {
        navigate('/not-subscribe');
      }
    },
    onCompleted: (data) => {
      if (data.checkUserPlay.responseCode === 700) {
        if (!isFreePlayAvailable()) {
          navigate('/not-subscribe');
        }
      }
    }
  });

  const [checkRemainingTime] = useMutation(CHECK_REMAINING_TIME, {
    onCompleted: (data) => {
      if (data && data.checkRemainingTime) {
        const { msisdn, minutes_remaining } = data.checkRemainingTime;
        // console.log(`msisdn: ${msisdn}`);
        // console.log(`minutes_remaining: ${minutes_remaining}`);

        const minutesRemainingNumber = parseInt(minutes_remaining, 10);
        setMinutesRemainingNumber(minutesRemainingNumber); // Update state with minutesRemainingNumber

        console.log(`Parsed minutes_remaining: ${minutesRemainingNumber}`);

        if (minutesRemainingNumber === 0) {
          console.log('Navigating to /not-subscribe');
          navigate('/not-subscribe');
        } else {
          console.log(`Remaining time: ${minutesRemainingNumber} minutes`);
        }
      } else {
        console.error('Invalid data returned from mutation:', data);
      }
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    }
  });

  useEffect(() => {
    const msisdn = Cookies.get('msisdn');
    const newUser = localStorage.getItem('newUser');
    const freePlayAvailable = isFreePlayAvailable();

    if (!msisdn && !freePlayAvailable) {
      navigate('/');
    }

    if (!newUser) {
      checkUserPlay();
    }

    checkRemainingTime();

    // Set up interval to call checkRemainingTime every 10 minutes
    const intervalId = setInterval(() => {
      checkRemainingTime();
    }, 10 * 60 * 1000); // 10 minutes

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [checkUserPlay, checkRemainingTime, navigate]);

  const handleUserPlay = () => {
    localStorage.removeItem('newUser');
    setTimeout(() => {
      checkUserPlay();
      checkRemainingTime();
    }, 300000); // 5 minutes
  };

  const [timerExpired, setTimerExpired] = useState(false);

  const handleTimerEnd = () => {
    setTimerExpired(true);
  };

  const CountdownTimer = ({ initialTime, onTimerEnd }) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
      const interval = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(interval);
          onTimerEnd();
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [timeLeft, onTimerEnd]);

    const formatTime = (time) => {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor(time % 60);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const formatRemainingTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} hours ${remainingMinutes} minutes`;
    };

    return (
      <Typography variant="h6" component="span" sx={{ ml: 2, color: '#0376c9' }}>
        Free play remaining: {formatRemainingTime(minutesRemainingNumber)}&nbsp;&nbsp;
      </Typography>
    );
  };

  return (
    <Page title="Free Games | Games Poa App">
      <Container maxWidth="xl">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4
            }}
          >
            <Typography id="modal-modal-title" variant="p" component="p">
              You are currently using your {APP_CONFIG.FREE_PLAY_TIME} hours free play.
            </Typography>
            <Button onClick={handleClose} sx={{ marginTop: '30px' }}>
              Ok
            </Button>
          </Box>
        </Modal>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '24px'
          }}
        >
          {!timerExpired && <CountdownTimer initialTime={remainingTimeForFreePlay()} onTimerEnd={handleTimerEnd} />}
        </Box>
        <AppSummaryGames isPremium={false} handleUserPlay={handleUserPlay} />
      </Container>
    </Page>
  );
};

export default FreeGames;
